<template>
  <div class="loading_warp" v-if="isLoadingShow">
    <Loading/>
  </div>
</template>
<script>
import Loading from "@/components/Loading"

export default {
  props: {
    isLoadingShow: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    Loading
  }
}
</script>
<style lang="scss" scoped>
.loading_warp {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  overflow: hidden;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
